import mindbodyImg from './assets/mindbody.svg';

type managedSsoCompanyParams = {
  logo: string;
  background: string;
};

export enum ParentCompanies {
  MINDBODY = 'Mindbody',
}

export const managedSsoConfig = new Map<string, managedSsoCompanyParams>([
  [
    ParentCompanies.MINDBODY,
    {
      logo: mindbodyImg,
      background: 'radial-gradient(115.19% 115.19% at 50% 50%, #E9FED3 8.5%, #AAFFD8 88%)',
    },
  ],
]);
