import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/SyncRunDetail_EDSLib_Query.graphql';

export const SyncRunDetailEntryPoint = createEntryPoint({
  component: () => import('./SyncRunDetail'),
  getQueries: ({ params }) => {
    return {
      query: {
        parameters: Query,
        variables: {
          syncId: params.id || '',
          syncRunId: params.syncRunId || '',
        },
      },
    };
  },
});
