export enum Routes {
  Dashboard = '/dashboard' /* deprecated and redirects to /analytics/dashboard */,
  SignUpUnitsRoot = '/subscribers',
  SignUpUnits = '/subscribers/sign-up-units',
  Subscribers = '/crm',
  Journeys = '/journeys',
  JourneysAI = '/ai-journeys',
  JourneysStandard = '/journeys/list',
  Campaigns = '/campaigns',
  Messages = '/messages',
  Segments = '/segments',
  Conversations = '/conversations',
  ConversationsInbox = '/conversations/inbox',
  ConversationsConcierge = '/conversations/concierge',
  Integrations = '/integrations',
  Analytics = '/analytics',
  AnalyticsDashboard = '/analytics/dashboard/overview',
  AnalyticsAiPro = '/analytics/dashboard/ai-pro',
  AnalyticsReports = '/analytics/reports',
  Reports = '/reports',
  Settings = '/settings',
  Monitor = '/monitor',
  Reference = '/reference',
  EmailEditorTestHarness = '/email-editor-test-harness',
  Tactical = '/tactical',
  Tag = '/tag',
  Offers = '/offers',
  Signin = '/signin',
  GoogleSigninCallback = '/signin/google/callback',
  Home = '/home',
  BrandKit = '/brandkit',
  Templates = '/templates',
  AIPro60DayTrial = '/ai-pro-trial-60',
  NotFound = '/404',
  ManagedSso = '/managed-sso',
}

export enum AdminRoutes {
  AdminCenter = '/admin',
  CampaignsControlCenter = '/admin/campaigns-control-center',
  CompanyCreation = '/admin/company/create',
  CompanySearch = '/admin/company/search',
  ConciergeAgents = '/admin/concierge-agents',
  EmailActivity = '/admin/email-activity',
  OnboardingFlow = '/admin/onboarding-flow',
  FeatureFlags = '/admin/feature-flags',
  IncidentManagement = '/admin/incident-management',
  JourneyTriggers = '/admin/journey-triggers',
  LitigatorOptOut = '/admin/litigator-opt-out',
  NotificationContent = '/admin/notification-content',
  ModalContent = '/admin/modal-content',
  MessageExplorer = '/admin/message-explorer',
  PhoneNumbers = '/admin/phone-numbers',
  RateLimit = '/admin/rate-limit',
  ServiceDegradationWarning = '/admin/service-degradation-warning',
  Solutions = '/admin/solutions',
  Sftp = '/admin/sftp',
  CSTools = '/admin/cs-tools',
  AIJourneysTools = '/admin/ai-journeys-tools',
  AIProTools = '/admin/ai-pro-tools',
  SubscriberAudit = '/admin/subscriber-audit',
  IPManagement = '/admin/ip-management',
  Marketplace = '/admin/marketplace',
  CreativeAudit = '/admin/creative-audit',
  GenerativeAI = '/admin/generative-ai',
  AIJourneys = '/admin/ai-journeys',
  AutomatedPrograms = '/admin/automated-programs',
  Datastreams = '/admin/datastreams',
  MigrationControlCenter = '/admin/migration-control-center',
  Workloads = '/admin/workloads',
  DomainSuppressions = '/admin/domain-suppression-management',
}

export enum ConciergeRoutes {
  Concierge = '/concierge',
  CONVERSATIONS = '/concierge/conversations',
  CONVERSATION = '/concierge/conversation/:userId',
  CHAT = '/concierge/chat/:userId',
  COMPANIES = '/concierge/companies',
  SPECIALIZATIONS = '/concierge/specializations',
  COMPANY = '/concierge/companies/:companyId',
  FEEDBACK = '/concierge/feedback',
  MESSAGE_FEEDBACK = '/concierge/:userId/:messageId',
  TEMPLATE_REQUESTS = '/concierge/templates/requests',
  GLOBAL_TEMPLATES = '/concierge/templates/global',
}

export enum OnboardingRoutes {
  Onboarding = '/onboarding',
}

export type Values<T> = T[keyof T];

export type RoutePaths = Values<
  typeof Routes & typeof AdminRoutes & typeof ConciergeRoutes & typeof OnboardingRoutes
>;

export type MFEAppNames =
  | 'subscribers-ui'
  | 'journeys-ui'
  | 'conversations-ui'
  | 'messages-ui'
  | 'segmentation-ui'
  | 'integrations-ui'
  | 'analytics-ui'
  | 'settings-ui'
  | 'reference-ui'
  | 'offers-ui'
  | 'onboarding-ui'
  | 'tactical-ui'
  | 'tag-ui'
  | 'admin-center-ui'
  | 'concierge-ui'
  | 'home-ui'
  | 'brand-kit-ui'
  | 'email-editor-test-harness-ui'
  | 'ml-ui'
  | 'templates-ui';
