import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, Icon, LoadingIndicator, Text, Wordmark } from '@attentive/picnic';
import {
  attentiveAuthClient,
  parseExternalCompanyId,
  parseRedirectPath,
  StrategyType,
  useLoginStrategyClient,
} from '@attentive/auth-core';
import { inSneakPreview, sneakPreviewCommitSha } from '@attentive/sneak-preview-utils';
import { managedSsoConfig, ParentCompanies } from './config';

export const ManagedSso = () => {
  const location = window.location;
  const navigate = useNavigate();
  const loginStrategyClient = useLoginStrategyClient();
  const parentCompany = ParentCompanies.MINDBODY; // hardcoded as mindbody is the only option right now
  const configParams = managedSsoConfig.get(parentCompany);

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

  useEffect(() => {
    const currentCommitSha = () => {
      if (inSneakPreview(location.pathname)) {
        return sneakPreviewCommitSha(location.pathname);
      }
    };
    const getStrategy = async () => {
      if (!email) {
        return;
      }
      return loginStrategyClient.getStrategy(email);
    };
    const redirectPath = parseRedirectPath(location);
    const commitSha = currentCommitSha();
    const externalCompanyId = parseExternalCompanyId(location);
    getStrategy()
      .then((strategy) => {
        if (strategy?.type === StrategyType.SAML) {
          const connectionName = strategy.connectionName;
          attentiveAuthClient.loginWithSso(
            connectionName,
            redirectPath,
            commitSha,
            externalCompanyId
          );
        } else {
          navigate('/signin', { replace: true });
        }
      })
      .catch(() => {
        navigate('/signin', { replace: true });
      });
  }, [email, loginStrategyClient, location, navigate]);

  if (!configParams) {
    // this will not happen in the current state as the only option is hardcoded
    <Navigate to="/signin" replace />;
    return null;
  }

  return (
    <Box
      css={{
        background: configParams.background,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box css={{ display: 'flex', alignItems: 'center', mb: '$space10' }}>
          <Box as="img" src={configParams.logo} alt={`${parentCompany} logo`} />
          <Icon size="large" name="PlusSign" css={{ m: '0 $space12' }} />
          <Wordmark title="Attentive" width="184px" />
        </Box>
        <LoadingIndicator css={{ mb: '$space4' }} />
        <Text>You are being redirected to Attentive</Text>
      </Box>
    </Box>
  );
};
